<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-imgbox">
        <div class="card-img">
          <img src="@/assets/img/person.png" alt="" />
        </div>
      </div>
      <div class="card-content">
        <div class="card-a">
          {{ data.name}}
        </div>
        <div class="card-b">
          <span>{{data.position}}</span>
          <span class="distance">{{ data.mobile}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 19px 15px 18px 14px;
    .card-imgbox {
      width: 45px;
      height: 45px;
      background-color: $color-01;
      border-radius: 50%;
      margin-right: 19.5px;
      img {
        width: 45px;
        border-radius: 50%;
      }
    }
    .card-content {
      flex: 1;
      text-align: left;
      margin-top: 2px;
      .card-a {
        color: $color-333;
        font-size: $font-15;
        font-weight: 600;
        margin-bottom: 13px;
      }
      .card-b {
        font-size: $font-14;
        color: $color-999;
        text-align: left;
        margin-bottom: 15px;
        .distance{
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
