<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-time">
        <div class="card-data">{{ data.trackTime ? data.trackTime : '—/—'}}</div>
        <div class="card-year">{{ data.trackYear ? data.trackYear : '——'}}</div>
        <div class="card-line"></div>
      </div>
      <div class="card-content">
        <div class="card-conts">
          <div class="card-detail">
            <slot>
              <div class="card-a">{{`跟进产品：${data.comboName}`}}</div>
              <div class="card-b">{{data.status}}</div>
            </slot>
            <div class="card-c">{{data.trackType}}<i class="icon-joyo arrows">&#xe66c;</i></div>
          </div>
          <div class="card-remark">
            {{ `跟进内容：${data.trackRemark}`}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box{
  width:100%;
  // height:78px;
  background-color: $color-FFF;
  padding-top:10px ;
  .card-cont{
    display: flex;
    justify-content: flex-start;
    padding: 0 14.5px;
    text-align: left;
    .card-time{
      margin-right:25px;
      display: flex;
      flex-direction: column;
      .card-data{
        color:$color-333;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .card-year{
        color:$color-999;
        font-size:$font-12;
        margin-bottom:10px ;
      }
      .card-line{
        width: 1px;
        flex:1;
        background: $color-E6;
        margin: 0 auto;
      }
    }
    .card-content{
      flex: 1;
      .card-conts{
        width:100%;
        margin-bottom: 40px;
        .card-detail{
          display: flex;
          justify-content:space-between;
          margin-bottom: 16px;
          .card-a{
            color:$color-333;
            font-size:$font-15;
            font-weight: 600;
          }
          .card-b{
            color:$color-321;
            font-size:$font-14;
            font-weight: 600;
            flex: 0 0 60px;
            text-align: right;
          }
          .card-c{
            color:$color-999;
            font-size:$font-14;
            flex: 0 0 50px;
            text-align: right;
            .arrows{
              color:$color-CCC;
              font-size:$font-12;
              margin-left: 5px;
            }
          }
          .card-d{
            color:$color-font;
            font-size:$font-14;
            line-height: 16px;
          }
        }
        .card-remark{
          font-size: $font-14;
          color: $color-999;
        //   width:250px;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 3;
        // /*! autoprefixer: off */
        //   -webkit-box-orient: vertical;
        //   /* autoprefixer: on */
        //   white-space: normal !important;
          line-height: 1.5;
        }
      }
    }
  }

}

</style>
